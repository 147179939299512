import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import scrollTo from 'gatsby-plugin-smoothscroll';

import Layout from '../../components/Layout';
import './index.scss';

const ProjectsPage = () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Layout>
        <Helmet>
          <title>事業内容| STINGS</title>
          <meta property="og:title" content="事業内容 | STINGS" />
          <meta name="twitter:title" content="事業内容 | STINGS"></meta>
        </Helmet>
        <div className="content">
          <div className="business-header">
            <div className="section-heading-blue">
              <h2>PROJECTS</h2>
            </div>
          </div>
          <div className="business-nav">
            <div className="business-nav__inner">
              <button
                onClick={() => scrollTo('#chara_dev')}
                className="square-btn-business"
              >
                <span>自社キャラクター開発事業 ></span>
              </button>
              <button
                onClick={() => scrollTo('#chara_pd')}
                className="square-btn-business"
              >
                <span>キャラクタープロダクション事業 ></span>
              </button>
              <button
                onClick={() => scrollTo('#cd')}
                className="square-btn-business"
              >
                <span>クリエティブディレクション ></span>
              </button>
            </div>
          </div>
          <div className="business-content" id="chara_dev">
            <div className="business-title">
              <span>Character development</span>
              <div className="heading-blue">
                <h3>自社キャラクター開発事業</h3>
              </div>
            </div>
            <div className="business-content__inner">
              <div className="business-message">
                <h4 className="business-heading-center">
                  STINGSはコミュニケーションを届ける仕事をしています。
                </h4>
                <p>
                  SNSに投稿されたイラストや友達から送られてきたスタンプで嬉しくなって
                  ほしい。ふとした瞬間にグッズを眺めて、やさしい気分になってほしい。
                  たまのリアルイベントで心に残る思い出を作ってほしいと願っています
                </p>
              </div>
              <Img
                fluid={data.yasausa.childImageSharp.fluid}
                className="business-yasausa-img"
              />
              <p className="business-yasausa-caption">
                「やさしい たのしい
                みんなの友だち」である『やさうさちゃん』と一緒にやさしい気分になりたい人をキャラクターの力で応援することを念頭にグッズ開発やリアルイベントなど様々な活動を
                実施しています。
              </p>
            </div>
            <ul className="business-list">
              <li className="business-list-item__half">
                <Img fluid={data.goods.childImageSharp.fluid} />
                <h4 className="business-heading">グッズ開発</h4>
                <p>
                  自社デザインのグッズ開発及び販売をしています。古今東西のカルチャーの良い部分を選択的にマッシュアップし、流行の半歩先を見据えたデザインを目指して制作しています。
                </p>
              </li>
              <li className="business-list-item__half">
                <Img fluid={data.event.childImageSharp.fluid} />
                <h4 className="business-heading">リアルイベント</h4>
                <p>
                  私達にとってリアルイベントは実際にファンと触れ合うことで「いま、ここ」についての理解をアップデートする重要な機会です。みんなに楽しんでもらえているのかどうか直接自分たちの目で見て理解することが必要だと考えています。
                </p>
              </li>
              <li className="business-list-item">
                <div class="business-list-thumbnail2">
                  <Img fluid={data.licence1.childImageSharp.fluid} />
                  <Img fluid={data.licence3.childImageSharp.fluid} />
                </div>
                <h4 className="business-heading">ライセンシー事業</h4>
                <p>
                  『やさうさちゃん』のライセンスを提供し、グッズ制作やコラボカフェなどのタイアップ施策を実施しております。2019年からは全国の「サンキューマート」で商品展開を開始したほか、2020年8月に横浜RokuCafeにて開催したコラボカフェでは連日予約だけで満席となる盛況を迎えることができました。今後も様々なブランドとタイアップしたイベント、商品開発のお手伝いをさせていただければと考えております。
                </p>
              </li>
            </ul>
          </div>
          <div className="business-section-border" id="chara_pd"></div>
          <div className="business-content">
            <div className="business-title">
              <span>Character production</span>
              <div className="heading-blue">
                <h3>キャラクタープロダクション事業</h3>
              </div>
            </div>
            <p className="business-heading-text">
              「やさうさちゃん」運用のノウハウをもとに、クライアント企業の新規キャラクター開発や既存キャラクターの育成支援を行っております。SNS運用や商品デザイン、リアルイベントを実施していくことで、「企業の顔」を低コストから育成していくことが可能です。
            </p>
            <div className="cp-content1">
              <div className="">
                <h4 className="business-heading">
                  「かわいい」だけで終わらせないために
                </h4>
                <p className="business-text__half">
                  通常のマーケティング施策と同様にキャラクター運用にも正解はありません。目的やターゲット属性に応じたコミュニケーション設計と、継続的な改善が不可欠です。
                  STINGSではキャラクター開発のノウハウに加えて、「誰に」「何をして」「どんな感情を抱いてもらえたいか」を考えながら運用・改善していく広告的発想を加えることで、ただ可愛いだけのキャラクター運用を超えた運用を実現します。
                </p>
              </div>
              <Img fluid={data.design_thinking.childImageSharp.fluid} />
            </div>

            <div className="cp-content2">
              <Img fixed={data.yasausa_cp.childImageSharp.fixed} />
              <div>
                <h4 className="business-heading">「企業の顔」を定着させる</h4>
                <p className="business-text">
                  企業のマーケティング活動においても、商品名やブランド名を浸透させるのは苦労の連続です。キャラクターは情報ではなくイメージとして人々の記憶に残りやすいことから、「企業の顔」として認知を広げやすい特性を持っています。認知心理学の知見によると、「かわいいモノ」を見た人はその対象に近づいてそばにいたいという気持ち
                  = 接近動機づけが喚起されることがわかっています。
                </p>
                <p className="business-text">
                  キャラクターへのビジュアル的な好意度がブランドへの好感度と一体化し、購買行動などを喚起する要因となり得るのです。また、オンライン・オフライン問わずキャラクターには「そのキャラクターかわいいね！」といったコミュニケーションのきっかけを作る力があります。こうした特徴は企業からの一方的なメッセージ発信ではなく、熱心な”ファン”の口コミ増加を重要視する近年のマーケティングトレンドとの親和性が極めて高いと言えるでしょう。
                </p>
              </div>
            </div>
            <div className="cp-content3">
              <div>
                <h4 className="business-heading">小さく始めて、大きく広げる</h4>
                <p className="business-text">
                  キャラクター運用の仮説検証は、素早く大量に積み重ねることが重要なため、まずはSNSへのイラスト投稿のように小規模かつ検証結果の確認までに時間がかかりにくいものから始め、知見を貯めていくのがおすすめです。一定の成果を確認できた後は、徐々にキャラクターを用いたコミュニケーションを商品デザインや各種広告に派生させていくことで、効率を最大化させていくステップに移行します。
                </p>
              </div>
              <div className="business-graph">
                <Img fluid={data.step.childImageSharp.fluid} />
              </div>
            </div>
          </div>
          <div className="business-section-border" id="cd"></div>
          <div className="business-content">
            <div className="business-title">
              <span>Character production</span>
              <div className="heading-blue">
                <h3>クリエイティブディレクション</h3>
              </div>
            </div>
            <p className="business-heading-text">
              キャラクター運用を通じて培ったクリエイティブを制作・編集する知見を応用し、SNSマーケティングや商品訴求に向けたクリエイティブ企画及びディレクションを実施しております。
            </p>

            <div className="business-content__inner">
              <h4 className="business-heading">
                生活者に「届く」クリエイティブ制作
              </h4>
              <p className="business-text">
                現代の生活者は、テレビ、SNS、Youtubeなど複数のメディアを行き来しつつ1日の大半を過ごしています。多種多様なメディアとの可処分時間を巡る競争を勝ち抜き、生活者にメッセージを届けるのは容易ではありません。
                生活者は、あふれかえった情報の中から注目に値するコンテンツを見つけ出すため、ほとんど一瞬でコンテンツにエンゲージするか否かの判断をくだしています。企業が発信するメッセージに関しても、生活者にとって注目に値するクリエイティブであることが重要です。
              </p>
              <div className="business-graph">
                <Img fluid={data.time_graph.childImageSharp.fluid} />
              </div>
              <div className="cd-content1">
                <p className="business-text__half">
                  特にスマホネイティブ世代のトレンドはビジュアルを重視した方向へと変化を続けています。なかでもエンゲージメント率が高く製作コストが低いイラスト制作は、ビジュアル訴求のPDCAを回していくのに最適な手段です。
                </p>
                <div className="business-comparison">
                  <Img fluid={data.comparison.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </div>
          <section className="business-contact">
            <div className="business-contact__inner">
              <h4 className="business-heading">CONTACT</h4>
              <p>
                事業・キャラクターIPコンテンツに関するお問い合わせはこちらからお願いします
              </p>
              <Link to="/contact" className="square-btn-business">
                <span>お問合せフォーム</span>
              </Link>
            </div>
          </section>
        </div>
      </Layout>
    )}
  />
);

export default ProjectsPage;

const query = graphql`
  query {
    yasausa: file(relativePath: { eq: "biz_yasausa.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    goods: file(relativePath: { eq: "biz_goods.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    event: file(relativePath: { eq: "biz_event.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    licence1: file(relativePath: { eq: "biz_licence1.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    licence3: file(relativePath: { eq: "biz_licence3.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    design_thinking: file(relativePath: { eq: "biz_design_thinking.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yasausa_cp: file(relativePath: { eq: "biz_face.png" }) {
      childImageSharp {
        fixed(width: 160, height: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    step: file(relativePath: { eq: "biz_step.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    time_graph: file(relativePath: { eq: "biz_time_graph.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    comparison: file(relativePath: { eq: "biz_comparison.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
